import React, { Component } from 'react'
import Layout from '../components/layout'
import { connect } from 'react-redux'

class ThankYou extends Component {

  state ={
    home_zip: ''
  }

  componentDidMount(){
    const zip = window.location.search.slice(10)
    this.setState({
      home_zip: zip
    })
  }
  render () {
    // const {home_zip, client_name, sub_id1, sub_id2, ip_address, name_first, name_last, dob, universal_leadid, home_street} = this.props.formState
    return (
    <Layout location={this.props.location}>
      <div className="container thank-you-page">
        <div className="flex-row">
          <div className="flex-small">
          <h1>Thank you</h1>
          <p>We have passed your information along to our appraisal team, and one of them will be reaching out to you soon to verify a few details, so we can help you get a quote for a cash offer on your policy.
  If you have any questions, feel free to call us at <a href="tel:18443259235">(844) 325-9235</a>.</p>
          </div>
        </div>
        <div className="flex-row">
          <div className="flex-small">
            <div className="iframe">
              <iframe title="cpcman" src={`https://cpcman.torchte.ch/fetch/renderPage?site=15&zip=${this.state.home_zip}&affid=${process.env.GATSBY_AFFID}&placement_id2=-unazqFOgzKf3HdVQUJtDxKkn9zo9A&displayId=16801&publisherId=40823&vmProdId=501&zipcode=${this.state.home_zip}&param1=227882&param2=268397&param3=3530&placement_id=SEewkn2o09k-rPuZ3GbjpZ8EU7fing"`} frameBorder="0"
              style={{
                overflow: "hidden",
                height: "100%",
                width: "100%"
              }} height="100%" width="100%"></iframe>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
  }
}

const mapStateToProps = ({ formState }) => {
  return { 
    formState
  }
}

export default connect( mapStateToProps, null )( ThankYou )